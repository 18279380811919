import _objectSpread from "D:/projects/operation-management-background/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.array.find.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.concat.js";
import debounce from "lodash/debounce";
import Follows from "./follows.vue";
import { DcVideoApi } from "@/api";
import previewMixin from "@/utils/previewMixin";
export default {
  components: {
    Follows: Follows
  },
  mixins: [previewMixin],
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: function _default() {
        return this.$t('dcReview.detail');
      }
    },
    editId: [String, Number],
    width: {
      type: Number,
      default: 1200
    },
    showNext: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    // newTitle() {
    //   return `${this.title} 【视频ID: ${this.editId}】`;
    // },
    templateMsg: function templateMsg() {
      var _this = this;
      if (!this.templateCode) return undefined;
      var it = this.templates.find(function (item) {
        return item.code === _this.templateCode;
      }) || {};
      return it === null || it === void 0 ? void 0 : it.message;
    },
    module: function module() {
      // 列表/搜索等都需要根据模块来显示
      return this.$route.name;
    }
  },
  watch: {
    visible: function visible(newVal) {
      if (newVal) {
        this.submiting = false;
        this.find();
      } else {
        this.item = {};
        this.isShowReason = false;
        this.templateCode = null;
      }
    }
  },
  mounted: function mounted() {
    this.find();
  },
  data: function data() {
    return {
      form: this.$form.createForm(this),
      item: {},
      isShowReason: false,
      timer: null,
      templates: [],
      // 模板列表
      templateCode: null,
      // 模板代码

      spinning: false,
      submiting: false,
      isdeal: false
    };
  },
  methods: {
    onClose: function onClose() {
      this.$emit("cancel");
    },
    handleNext: debounce(function () {
      if (this.spinning) return;
      this.form.resetFields();
      this.$emit("next");
    }, 300),
    onSubmit: function onSubmit() {
      var _this2 = this;
      this.form.validateFields(function (error, values) {
        if (!error) {
          var params = _objectSpread({}, values);
          params.videoids = [_this2.editId];

          // console.log('提交的数据', params);
          _this2.submiting = true;
          DcVideoApi.batchcheck(_objectSpread({}, params)).then(function () {
            _this2.toast(_this2.$t('common.success'), "success");
            _this2.submiting = false;
            _this2.isdeal = true;
            _this2.$emit("submit");
          }).catch(function (err) {
            _this2.submiting = false;
            // this.$emit("submit");
            // this.toast("操作失败," + err, "error");
          });
        }
      });
    },
    // 视频定位
    toPisition: function toPisition(seconds) {
      var video = this.$refs.video;
      video.pause();
      video.currentTime = seconds;
    },
    // 格式化视频时间
    formatVideoTime: function formatVideoTime(seconds) {
      function fillZero(num) {
        if (num >= 10) return "".concat(num);else return "0".concat(num);
      }
      seconds = Math.floor(seconds);
      var hh = Math.floor(seconds / 3600);
      seconds = seconds % 3600;
      var mm = Math.floor(seconds / 60);
      var ss = seconds % 60;
      if (hh > 0) return "".concat(fillZero(hh), ":").concat(fillZero(mm), ":").concat(fillZero(ss));else return "".concat(fillZero(mm), ":").concat(fillZero(ss));
    },
    // 下拉框筛选
    filterOption: function filterOption(input, option) {
      if (this.searchMode == "remote") return true;
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    },
    find: function find() {
      var _this3 = this;
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(function () {
        _this3.spinning = true;
        DcVideoApi.getdetails({
          id: _this3.editId
        }).then(function (data) {
          _this3.isdeal = data.status > 0 ? true : false;
          if (data.type == 2) {
            data.contenturl = data.contenturl.split(',');
          }
          _this3.item = data;
          _this3.spinning = false;
        }).catch(function () {
          _this3.spinning = false;
        });
      }, 200);
    },
    loadTemplates: function loadTemplates() {
      var _this4 = this;
      // this.spinning = true;
      DcVideoApi.rejectreason().then(function (res) {
        // this.spinning = false;
        _this4.templates = res;
      }).catch(function () {});
    },
    handleStatusChange: function handleStatusChange(event) {
      if (event.target.value == 2) {
        this.isShowReason = true;
        if (this.templates.length == 0) {
          this.loadTemplates();
        }
      } else {
        this.isShowReason = false;
      }
    },
    handleReason: function handleReason(val) {
      this.templateCode = val;
      this.form.resetFields(["reason"]);
    }
  }
};