var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("a-modal", {
    attrs: {
      title: _vm.$t("dcReview.detailTitleCompleted", {
        title: _vm.title,
        editId: _vm.editId
      }),
      width: _vm.width,
      visible: _vm.visible,
      footer: null,
      bodyStyle: {
        "min-height": "650px"
      },
      centered: "",
      destroyOnClose: true
    },
    on: {
      cancel: _vm.onClose
    }
  }, [_c("a-spin", {
    attrs: {
      spinning: _vm.spinning || _vm.loading
    }
  }, [_c("a-form", {
    attrs: {
      form: _vm.form,
      layout: "vertical",
      labelCol: {
        style: "width: 85px"
      }
    }
  }, [_c("a-row", {
    attrs: {
      gutter: 24
    }
  }, [_c("a-col", {
    attrs: {
      span: 10
    }
  }, [_c("a-form-item", {
    attrs: {
      label: _vm.$t("dcReview.viewDetail.state") + "："
    }
  }, [_c("a-radio-group", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["status", {
        rules: [{
          required: true,
          message: _vm.$t("dcReview.detailForm.message01")
        }],
        initialValue: _vm.item.isstatus > 0 ? _vm.item.isstatus : ""
      }],
      expression: "[\n                'status',\n                {\n                  rules: [{ required: true, message: $t('dcReview.detailForm.message01') }],\n                  initialValue: item.isstatus > 0 ? item.isstatus : '',\n                },\n              ]"
    }],
    attrs: {
      "button-style": "solid"
    },
    on: {
      change: _vm.handleStatusChange
    }
  }, [_c("a-radio-button", {
    attrs: {
      value: 1
    }
  }, [_vm._v(_vm._s(_vm.$t("common.pass")))]), _c("a-radio-button", {
    attrs: {
      value: 2
    }
  }, [_vm._v(_vm._s(_vm.$t("common.reject")))])], 1)], 1), _vm.isShowReason ? _c("a-form-item", {
    attrs: {
      label: _vm.$t("common.reason") + "："
    }
  }, [_c("a-select", {
    attrs: {
      placeholder: _vm.$t("dcReview.detailForm.message03"),
      "show-search": "",
      filterOption: _vm.filterOption,
      dropdownMatchSelectWidth: false,
      dropdownClassName: "kw-select-dropdown"
    },
    on: {
      change: _vm.handleReason
    },
    scopedSlots: _vm._u([{
      key: "dropdownRender",
      fn: function fn(menu) {
        return _c("div", {}, _vm._l(_vm.templates, function (item) {
          return _c("div", {
            key: item.code,
            on: {
              click: function click($event) {
                return _vm.handleReason(item.code);
              }
            }
          }, [_c("a-tooltip", {
            attrs: {
              destroyTooltipOnHide: true,
              placement: "topLeft"
            }
          }, [_c("template", {
            slot: "title"
          }, [_vm._v(" " + _vm._s(item.message) + " ")]), _vm._v(" " + _vm._s(item.message) + " ")], 2)], 1);
        }), 0);
      }
    }], null, false, 2153466096)
  }), _c("a-input", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["reason", {
        rules: [{
          required: true,
          message: _vm.$t("dcReview.detailForm.message02")
        }],
        initialValue: _vm.templateMsg
      }],
      expression: "[\n                'reason',\n                {\n                  rules: [{ required: true, message: $t('dcReview.detailForm.message02') }],\n                  initialValue: templateMsg,\n                },\n              ]"
    }],
    attrs: {
      placeholder: _vm.$t("dcReview.detailForm.placeholder04"),
      autocomplete: "off"
    }
  })], 1) : _vm._e(), _c("a-form-item", {
    attrs: {
      label: _vm.$t("common.remark") + "："
    }
  }, [_c("a-input", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["memo", {
        rules: [{
          max: 200,
          message: _vm.$t("dcReview.detailForm.message03")
        }],
        initialValue: _vm.item.memo
      }],
      expression: "[\n                'memo',\n                {\n                  rules: [\n                    {\n                      max: 200,\n                      message: $t('dcReview.detailForm.message03'),\n                    },\n                  ],\n                  initialValue: item.memo\n                },\n              ]"
    }],
    attrs: {
      placeholder: "",
      autocomplete: "off"
    }
  })], 1), _c("a-form-item", {
    attrs: {
      label: _vm.$t("common.nickname") + "："
    }
  }, [_c("div", {
    staticClass: "form-row-item"
  }, [_c("span", [_vm.item.usertype > 0 ? _c("img", {
    staticStyle: {
      position: "relative",
      top: "-2px",
      "margin-right": "2px"
    },
    attrs: {
      src: _vm.certified,
      alt: ""
    }
  }) : _vm._e(), _vm._v(_vm._s(_vm.item.username) + " ")]), _c("span", {
    staticClass: "cell"
  }, [_vm._v(_vm._s(_vm.$t("dcReview.fans")) + "："), _c("Follows", {
    attrs: {
      num: _vm.item.fansnum
    }
  })], 1), _c("span", {
    staticClass: "cell"
  }, [_vm._v(_vm._s(_vm.$t("common.userID")) + "：" + _vm._s(_vm.item.userid))])])]), _c("a-form-item", {
    attrs: {
      label: _vm.$t("dcReview.viewDetail.title") + "："
    }
  }, [_c("a-textarea", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["title", {
        rules: [{
          required: false,
          message: _vm.$t("dcReview.detailForm.message04")
        }],
        initialValue: _vm.item.title
      }],
      expression: "[\n                'title',\n                {\n                  rules: [{ required: false, message: $t('dcReview.detailForm.message04') }],\n                  initialValue: item.title,\n                },\n              ]"
    }],
    attrs: {
      "auto-size": {
        minRows: 1,
        maxRows: 6
      },
      placeholder: _vm.$t("dcReview.detailForm.placeholder01"),
      disabled: ""
    }
  })], 1), _c("a-form-item", {
    attrs: {
      label: " "
    }
  }, [_c("div", {
    staticClass: "actions"
  }, [_c("a-button", {
    directives: [{
      name: "hasPermission",
      rawName: "v-hasPermission",
      value: ["".concat(_vm.module, ":btn:detail")],
      expression: "[`${module}:btn:detail`]"
    }],
    attrs: {
      type: "primary",
      loading: _vm.submiting,
      disabled: _vm.isdeal
    },
    on: {
      click: _vm.onSubmit
    }
  }, [_vm._v(_vm._s(_vm.$t("dcReview.detailBtn.submit")))]), _vm.showNext ? _c("a-button", {
    attrs: {
      type: "primary",
      ghost: ""
    },
    on: {
      click: _vm.handleNext
    }
  }, [_vm._v(_vm._s(_vm.$t("dcReview.detailBtn.next")))]) : _vm._e(), _c("a-button", {
    on: {
      click: function click() {
        return _vm.$emit("previewLog");
      }
    }
  }, [_vm._v(_vm._s(_vm.$t("dcReview.detailBtn.viewLogs")))])], 1)]), _c("a-form-item", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.item.items && _vm.item.items.length,
      expression: "item.items && item.items.length"
    }],
    attrs: {
      label: " "
    }
  }, [_c("div", {
    staticClass: "form-tags"
  }, _vm._l(_vm.item.items, function (it, index) {
    return _c("p", {
      key: index
    }, [_vm._v(" [" + _vm._s(_vm.$t("common.tag")) + "]：" + _vm._s(it.label) + "，[" + _vm._s(_vm.$("dcReview.score")) + "]：" + _vm._s(it.confidence) + "，" + _vm._s(_vm.$t("common.location")) + "："), _c("a", {
      on: {
        click: function click($event) {
          return _vm.toPisition(it.time);
        }
      }
    }, [_vm._v(_vm._s(_vm.formatVideoTime(it.time)))])]);
  }), 0)])], 1), _c("a-col", {
    attrs: {
      span: 13,
      push: 1
    }
  }, [_vm.item.type == 1 ? [_c("div", {
    staticClass: "form-videos"
  }, [_c("video", {
    ref: "video",
    attrs: {
      src: _vm.item.contenturl,
      poster: _vm.item.coverurl,
      controls: "controls",
      controlslist: "nodownload"
    }
  })])] : _vm.item.type == 2 ? [_c("div", [_c("p", [_vm._v(_vm._s(_vm.$t("common.total")) + "：" + _vm._s(_vm.item.contenturl.length))]), _c("div", {
    staticClass: "imgs-wrap"
  }, _vm._l(_vm.item.contenturl, function (Itm, Idx) {
    return _c("img", {
      key: Idx,
      staticClass: "art-img",
      attrs: {
        src: Itm,
        alt: "图片"
      },
      on: {
        click: function click($event) {
          return _vm.previewImg(_vm.item.contenturl, "pic", Idx);
        }
      }
    });
  }), 0)])] : _vm._e()], 2)], 1)], 1)], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };